import React from 'react';
import PropTypes from 'prop-types';

const Footer = ({ logo, children, text = '', header_class, footer_notes = '', logo_class, title = '' }) => {
    return (
        <footer className={'py-12 mt-12 ' + header_class}>
            <a href="#" className="flex justify-center">
                {logo && <img alt="Logo" src={logo} className={`max-h-full w-auto mb-8 ${logo_class}`} />}
            </a>
            <div className="max-w-7xl mx-auto flex flex-col lg:flex-row justify-between px-6 lg:px-8">
                <div className="flex flex-col lg:items-start items-center lg:min-w-64 lg:border-r lg:border-gray-500 lg:pr-8">{children}</div>
                <div className="lg:pl-8 mt-8 lg:mt-0 text-left">
                    {title && <div className="text-lg lg:mt-0 mt-4 mb-4 font-bold">{title}</div>}
                    <div className="text-sm lg:mt-0 mt-4">{text}</div>
                    <div className="text-xs mt-8 font-bold">{footer_notes}</div>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    logo: PropTypes.string,
    logo_class: PropTypes.string,
    footer_notes: PropTypes.string,
    header_class: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Footer;
