import React from 'react';
import PropTypes from 'prop-types';

const ArticleAuthor = ({ href = '', article_author_class, image_url, name, pre_text }) => {
    return (
        <div className={`relative mt-4 flex items-center gap-x-2 ${article_author_class}`}>
            {image_url && <img alt={name} src={image_url} className="h-8 w-8 border border-[#702566] bg-gray-100" />}
            <div className="text-sm leading-6 flex">
                <p className="text-gray-600">{pre_text}&nbsp;</p>
                <a href={href} className="font-bold">
                    {name}
                </a>
            </div>
        </div>
    );
};

ArticleAuthor.propTypes = {
    href: PropTypes.string,
    name: PropTypes.string,
    pre_text: PropTypes.string,
    image_url: PropTypes.string,
    article_author_class: PropTypes.string,
};

export default ArticleAuthor;
