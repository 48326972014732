import React from 'react';
import PropTypes from 'prop-types';

const VerticalCard = ({
    href,
    title,
    alt_text,
    image_url,
    description,
    image_class,
    title_class,
    category_title,
    category_class,
    description_class,
    apply_drop_shadow = false,
}) => {
    const parsedApplyDropShadow = JSON.parse(apply_drop_shadow);

    const shadowClass = parsedApplyDropShadow ? 'shadow-lg' : '';

    return (
        <div className={`flex items-start ${shadowClass} pr-4 pb-4`}>
            {image_url && (
                <a href={href} className="cursor-pointer">
                    <img alt={alt_text} src={image_url} className={'object-cover bg-gray-100 w-32 h-32 ' + image_class} />
                </a>
            )}

            <div className="flex-1 ml-4">
                {category_title && (
                    <div className={`text-xs w-fit z-10 px-1 bg-green-700 uppercase font-medium text-white ${category_class}`}>
                        {category_title}
                    </div>
                )}
                <a href={href} className="cursor-pointer">
                    <h3 className={'my-1 line-clamp-2 font-bold leading-tight text-[#171632] hover:underline ' + title_class}>{title}</h3>
                    <p className={'mt-2 line-clamp-3 text-gray-600 ' + description_class}>{description}</p>
                </a>
            </div>
        </div>
    );
};

VerticalCard.propTypes = {
    href: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    alt_text: PropTypes.string,
    image_url: PropTypes.string.isRequired,
    description: PropTypes.string,
    image_class: PropTypes.string,
    title_class: PropTypes.string,
    category_title: PropTypes.string,
    category_class: PropTypes.string,
    description_class: PropTypes.string,
    apply_drop_shadow: PropTypes.string,
};

export default VerticalCard;
