import { createElement } from 'react';
import ReactDOM from 'react-dom/client';

import ArticleAuthor from './components/ArticleAuthor';
import ArticleContent from './components/ArticleContent';
import ArticleSidebar from './components/ArticleSidebar';
import Card from './components/Card';
import CardsContainer from './components/CardsContainers';
import CategoriesMenu from './components/CategoriesMenu';
import Container from './components/Container';
import Footer from './components/Footer';
import Header from './components/Header';
import HeaderLight from './components/HeaderLight';
import Hero from './components/Hero';
import Link from './components/Link';
import VerticalCard from './components/VerticalCard';

function convertStyleToReactLike(styleString) {
    const styleObject = {};

    if (!styleString) return styleObject;

    const stylePairs = styleString.split(';').filter(Boolean);

    stylePairs.forEach((pair) => {
        const [key, value] = pair.split(':').map((str) => str.trim());

        if (key && value) {
            const reactStyleKey = key.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
            styleObject[reactStyleKey] = value;
        }
    });

    return styleObject;
}

function convertAllStyles(props) {
    Object.keys(props).forEach((key) => {
        if (key.includes('style') && typeof props[key] === 'string') {
            props[key] = convertStyleToReactLike(props[key]);
        }
    });
}

function convertToReactElement(node) {
    if (node.nodeType === Node.ELEMENT_NODE) {
        const props = {};
        Array.from(node.attributes).forEach((attr) => {
            props[attr.name] = attr.value;
        });

        const children = Array.from(node.childNodes).map(convertToReactElement);

        convertAllStyles(props);

        return createElement(node.tagName.toLowerCase(), props, ...children);
    }

    if (node.nodeType === Node.TEXT_NODE) {
        const textContent = node.textContent.trim();
        return textContent ? textContent : null;
    }

    return null;
}

class BaseWebComponent extends HTMLElement {
    connectedCallback() {
        setTimeout(() => this.renderComponent(), 0);
    }

    renderComponent() {
        const root = ReactDOM.createRoot(this);
        const props = this.getPropsFromAttributes();
        const childrenElements = Array.from(this.childNodes).map(convertToReactElement);

        if (this.hasAttribute('on_click')) {
            props.onClick = new Function('event', this.getAttribute('on_click'));
        }

        root.render(createElement(this.getComponentClass(), props, ...childrenElements));
    }

    getPropsFromAttributes() {
        const props = {};
        Array.from(this.attributes).forEach((attr) => {
            props[attr.name] = attr.value;
        });
        return props;
    }
}

const componentMapping = {
    'lp-link': Link,
    'lp-card': Card,
    'lp-hero': Hero,
    'lp-header': Header,
    'lp-header-light': HeaderLight,
    'lp-footer': Footer,
    'lp-container': Container,
    'lp-vertical-card': VerticalCard,
    'lp-article-author': ArticleAuthor,
    'lp-article-content': ArticleContent,
    'lp-cards-container': CardsContainer,
    'lp-categories-menu': CategoriesMenu,
    'lp-article-sidebar': ArticleSidebar,
};

Object.entries(componentMapping).forEach(([tagName, componentClass]) => {
    customElements.define(
        tagName,
        class extends BaseWebComponent {
            getComponentClass() {
                return componentClass;
            }
        }
    );
});
