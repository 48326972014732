import React from 'react';
import PropTypes from 'prop-types';

const ArticleContent = ({ children, sub_title = '', h2_color = 'black', featured_image_url, featured_image_class, container_class }) => {
    const style = `
        h2 {
            color: ${h2_color};
            margin-bottom: 1rem;
            margin-top: 2rem;
            font-size: 22px; 
            line-height: 1.25; 
            font-weight: bold;
        }

        @media (min-width: 1024px) {
            h2 {
                font-size: 28px;
            }
        }
    `;

    const sidebar = children.find((node) => node?.type === 'lp-article-sidebar');
    const filteredChildren = children.filter((node) => node?.type !== 'lp-article-sidebar');

    return (
        <>
            <style>{style}</style>

            <div className={`flex gap-x-12 mt-6 lg:flex-row flex-col ${container_class}`}>
                <div className="flex flex-1 flex-col">
                    {sub_title && <div className="lg:text-lg text-sm font-bold">{sub_title}</div>}
                    {featured_image_url && <img className={`lg:mt-6 mt-3 ${featured_image_class}`} src={featured_image_url}></img>}
                    {filteredChildren}
                </div>

                {sidebar}
            </div>
        </>
    );
};

ArticleContent.propTypes = {
    children: PropTypes.node,
    h2_color: PropTypes.string,
    sub_title: PropTypes.string,
    featured_image_url: PropTypes.string,
    featured_image_class: PropTypes.string,
    container_class: PropTypes.string,
};

export default ArticleContent;
