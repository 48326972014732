import React, { useState } from 'react';
import PropTypes from 'prop-types';

const CategoriesMenu = ({ children, categories_menu_class }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`mx-auto lg:py-2 py-2 border-b ${categories_menu_class}`}>
            <div className="mx-auto  max-w-6xl">
                <button className="sm:hidden px-4 mx-auto flex text-black border-black border self-center" onClick={toggleMenu}>
                    {isOpen ? 'Hide Categories' : 'See Categories'}
                </button>

                <div className={`transition-all duration-300 ease-in-out overflow-hidden ${isOpen ? 'max-h-screen' : 'max-h-0'} sm:max-h-full`}>
                    <div
                        className={`flex flex-wrap justify-center sm:justify-between gap-4 sm:gap-0 sm:mt-0 lg:gap-8 mt-8 ${isOpen ? 'block' : 'hidden'} sm:flex px-5`}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

CategoriesMenu.propTypes = {
    children: PropTypes.node.isRequired,
    categories_menu_class: PropTypes.string,
};

export default CategoriesMenu;
