import React from 'react';
import PropTypes from 'prop-types';

const ArticleSidebar = ({ children }) => {
    return <div className="w-full">{children}</div>;
};

ArticleSidebar.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ArticleSidebar;
