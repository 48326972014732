import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const CardsContainer = ({ children, title_class, title = '', sub_title = '', desktop_column_qty = 3, header_class, container_class }) => {
    const [isDesktop, setIsDesktop] = useState(false);
    const [isTablet, setIsTablet] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsDesktop(width >= 1024);
            setIsTablet(width >= 500 && width < 1024);
        };

        handleResize();
        window?.addEventListener('resize', handleResize);

        return () => window?.removeEventListener('resize', handleResize);
    }, []);

    const containerStyle = {
        gap: '2rem',
        display: 'grid',
        gridTemplateColumns: isDesktop ? `repeat(${desktop_column_qty}, 1fr)` : isTablet ? 'repeat(1, 1fr)' : 'repeat(1, 1fr)',
    };

    const hasTitleOrSubTitle = title || sub_title;
    return (
        <>
            <div className={`mx-auto mt-12 ${header_class}`}>
                <div className={`lg:text-3xl font-bold tracking-tight text-gray-900 text-4xl ${title_class}`}>{title}</div>
                {sub_title && <p className="mt-2 text-lg leading-8 text-gray-600">{sub_title}</p>}
            </div>
            {hasTitleOrSubTitle && <hr className="mt-3"></hr>}
            <div className={`mx-auto place-content-center mt-4 max-w-2xl lg:mx-0 lg:max-w-none ${container_class}`} style={containerStyle}>
                {children}
            </div>
        </>
    );
};

CardsContainer.propTypes = {
    title: PropTypes.string,
    sub_title: PropTypes.string,
    title_class: PropTypes.string,
    children: PropTypes.node.isRequired,
    desktop_column_qty: PropTypes.string,
    header_class: PropTypes.string,
    container_class: PropTypes.string,
};

export default CardsContainer;
