import PropTypes from 'prop-types';
import React from 'react';
import { twMerge } from 'tailwind-merge';

const HeaderLight = ({
    logo,
    logo_height,
    logo_class,
    header_class,
    header_content_class,
    right_content_text,
    right_content_class,
    right_content_icon,
    right_content_icon_class,
    right_content_icon_text,
    right_content_icon_text_class,
    right_button_text,
    right_button_icon,
    right_button_icon_class,
    right_button_class,
    right_button_click,
}) => {
    const logoStyle = { height: logo_height };
    const logoClass = twMerge('h-6 h-8', logo_class);
    const headerClass = twMerge('bg-white border-b border-gray-300 shadow', header_class);
    const headerContentClass = twMerge('flex justify-between items-center p-2 lg:px-5 max-w-screen-xl mx-auto', header_content_class);
    const rightContentClass = twMerge('flex flex-col', right_content_class);
    const rightIconClass = twMerge('h-5 w-5', right_content_icon_class);
    const rightIconTextClass = twMerge('text-lg font-bold text-blue-500', right_content_icon_text_class);
    const rightButtonClass = twMerge('flex items-center space-x-2 text-lg font-bold text-white bg-blue-500 p-2 px-3', right_button_class);
    const rightContentIconClass = twMerge('h-5 w-5', right_button_icon_class);

    function onRightContentButtonClick() {
        if (right_button_click && typeof right_button_click === 'string') eval(right_button_click);
        if (right_button_click && typeof right_button_click === 'function') right_button_click();
    }

    return (
        <header className={headerClass}>
            <div className={headerContentClass}>
                {/* Logo Container */}
                <div className="flex-shrink-0">
                    <img src={logo} alt="header-logo" style={logoStyle} className={logoClass} />
                </div>

                {/* Right Side Container */}
                <div className="flex flex-row items-end gap-2">
                    {right_content_text && right_content_icon && right_content_icon_text && (
                        <div className={rightContentClass}>
                            <p className="text-xs lg:text-sm font-medium text-gray-700 text-right">{right_content_text}</p>
                            <div className="flex items-center space-x-2 justify-end">
                                <img src={right_content_icon} alt="right-icon" className={rightIconClass} />
                                <p className={rightIconTextClass}>{right_content_icon_text}</p>
                            </div>
                        </div>
                    )}
                    {right_button_text && (
                        <button type="button" className={rightButtonClass} onClick={onRightContentButtonClick}>
                            <img src={right_button_icon} alt="right-button-icon" className={rightContentIconClass} />
                            <span>{right_button_text}</span>
                        </button>
                    )}
                </div>
            </div>
        </header>
    );
};

HeaderLight.propTypes = {
    logo: PropTypes.string,
    logo_height: PropTypes.string,
    logo_class: PropTypes.string,
    header_class: PropTypes.string,
    header_content_class: PropTypes.string,
    right_content_class: PropTypes.string,
    right_content_text: PropTypes.string,
    right_content_icon: PropTypes.string,
    right_content_icon_class: PropTypes.string,
    right_content_icon_text: PropTypes.string,
    right_content_icon_text_class: PropTypes.string,
    right_button_text: PropTypes.string,
    right_button_icon: PropTypes.string,
    right_button_icon_class: PropTypes.string,
    right_button_class: PropTypes.string,
    right_button_click: PropTypes.func,
};

export default HeaderLight;
