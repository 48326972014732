import React from 'react';
import PropTypes from 'prop-types';

const Link = ({ href = '', children, link_class, ...props }) => {
    return (
        <a href={href} className={'leading-6 hover:underline ' + link_class} {...props}>
            {children}
        </a>
    );
};

Link.propTypes = {
    href: PropTypes.string,
    link_class: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Link;
