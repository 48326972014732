import React from 'react';
import PropTypes from 'prop-types';

const Hero = ({
    children,
    cta_url = '#',
    height = '350px',
    heading = 'Heading',
    sub_heading = 'Subheading',
    cta_text = 'Call to action',
    background_image = 'https://tecdn.b-cdn.net/img/new/slides/041.webp',
}) => {
    const heroStyle = {
        backgroundImage: `url(${background_image})`,
        height,
    };

    return (
        <div className={`relative h-[350px] overflow-hidden bg-cover bg-[50%] bg-no-repeat`} style={heroStyle}>
            <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-black/60 bg-fixed">
                <div className="flex h-full items-center justify-center">
                    <div className="px-6 text-center text-white md:px-12">
                        {children ? (
                            children
                        ) : (
                            <>
                                <h1 className="mb-6 text-5xl font-bold">{heading}</h1>
                                <h3 className="mb-8 text-3xl font-bold">{sub_heading}</h3>
                                <a
                                    href={cta_url}
                                    className="inline-block rounded border-2 border-neutral-50 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-300 hover:text-neutral-200 focus:border-neutral-300 focus:text-neutral-200 focus:outline-none focus:ring-0 active:border-neutral-300 active:text-neutral-200 dark:hover:bg-neutral-600 dark:focus:bg-neutral-600"
                                >
                                    {cta_text}
                                </a>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

Hero.propTypes = {
    heading: PropTypes.string,
    sub_heading: PropTypes.string,
    cta_text: PropTypes.string,
    cta_url: PropTypes.string,
    children: PropTypes.node,
    height: PropTypes.string,
    background_image: PropTypes.string,
};

export default Hero;
