import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const Header = ({ logo, children, logo_height = '68px', main_color = 'black', ...props }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [showLeftShade, setShowLeftShade] = useState(false);
    const [showRightShade, setShowRightShade] = useState(false);
    const scrollRef = useRef(null);

    useEffect(() => {
        const checkScrollPosition = () => {
            if (scrollRef?.current) {
                const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;

                setShowLeftShade(scrollLeft > 0);
                setShowRightShade(scrollLeft + clientWidth < scrollWidth - 1);
            }
        };

        setTimeout(() => {
            checkScrollPosition();
        }, 10);
        scrollRef?.current?.addEventListener('scroll', checkScrollPosition);

        return () => scrollRef?.current?.removeEventListener('scroll', checkScrollPosition);
    }, []);

    const scrollMenu = (direction) => {
        if (scrollRef?.current) {
            scrollRef?.current?.scrollBy({ left: direction === 'left' ? -300 : 300, behavior: 'smooth' });
        }
    };

    const logoStyle = {
        height: logo_height,
    };

    return (
        <header {...props}>
            <nav className="mx-auto flex max-w-7xl items-center justify-between">
                <a href="/" className="lg:mx-auto ml-3">
                    {logo && <img alt="Logo" src={logo} style={logoStyle} className="max-h-full w-auto" />}
                </a>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        onClick={() => setMobileMenuOpen(true)}
                        className="m-2.5 inline-flex items-center justify-center rounded-md text-gray-700"
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke={main_color}>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    </button>
                </div>
            </nav>
            <div className="relative">
                <div className="lg:flex hidden items-center justify-center relative mt-4 max-w-screen-xl mx-auto">
                    {showLeftShade && (
                        <button
                            onClick={() => scrollMenu('left')}
                            className="absolute -left-7 top-1/2 transform -translate-y-1/2 bg-transparent p-2 z-30"
                        >
                            <svg
                                className="h-6 w-6 text-black"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke={main_color}
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                            </svg>
                        </button>
                    )}

                    <div
                        className="flex lg:gap-x-12 overflow-x-auto scrollbar-hide relative"
                        ref={scrollRef}
                        style={{ scrollBehavior: 'smooth', scrollbarWidth: 'none', maxWidth: '100%' }}
                    >
                        {children}
                    </div>

                    {showRightShade && (
                        <button
                            className="absolute -right-9 top-1/2 transform -translate-y-1/2 bg-transparent p-2"
                            onClick={() => scrollMenu('right')}
                        >
                            <svg
                                className="h-6 w-6 text-black z-30"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke={main_color}
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                            </svg>
                        </button>
                    )}

                    {showLeftShade && (
                        <div className="absolute left-0 top-0 bottom-0 w-8 bg-gradient-to-r from-white to-transparent pointer-events-none"></div>
                    )}
                    {showRightShade && (
                        <div className="absolute right-0 top-0 bottom-0 w-8 bg-gradient-to-l from-white to-transparent pointer-events-none"></div>
                    )}
                </div>

                <div className="lp-header-separator h-2 bg-blue-500 mt-4" style={{ backgroundColor: main_color }}></div>

                <div
                    className={`fixed inset-0 z-40 bg-black bg-opacity-50 transition-opacity duration-300 ${mobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                    onClick={() => setMobileMenuOpen(false)}
                ></div>
                <div
                    className={`fixed inset-y-0 right-0 z-50 w-64 bg-white p-6 transform transition-transform duration-300 ${mobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}
                >
                    <div className="flex items-center justify-between">
                        <div className="mt-6 flex-col flex space-y-4">{children}</div>
                        <button type="button" onClick={() => setMobileMenuOpen(false)} className="-m-2.5 rounded-md p-2.5 text-gray-700 mb-auto">
                            <span className="sr-only">Close menu</span>
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke={main_color}>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
};

Header.propTypes = {
    text: PropTypes.string,
    logo: PropTypes.string,
    main_color: PropTypes.string,
    logo_height: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Header;
