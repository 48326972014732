import React from 'react';
import PropTypes from 'prop-types';

const Card = ({
    href,
    date,
    title,
    alt_text,
    image_url,
    author_href,
    author_name,
    description,
    title_class,
    category_href,
    category_title,
    category_class,
    container_class,
    author_subtitle,
    author_image_url,
    description_class,
    apply_drop_shadow = false,
    image_url_class,
}) => {
    const parsedApplyDropShadow = JSON.parse(apply_drop_shadow);

    const shadowClass = parsedApplyDropShadow ? 'shadow-lg' : '';

    return (
        <div className={`flex flex-col items-start justify-between lg:max-w-[370px] ${shadowClass} pr-4 pb-4 ${container_class}`}>
            {image_url && (
                <a href={href} className="cursor-pointer w-full">
                    <div className="relative w-full">
                        <img
                            alt={alt_text}
                            src={image_url}
                            className={`aspect-[16/9] w-full bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2] ${image_url_class}`}
                        />
                    </div>
                </a>
            )}

            <div className="max-w-xl">
                <a href={href} className="cursor-pointer">
                    <div className="group relative min-h-32">
                        <h3 className={`mt-3 font-bold text-lg text-gray-900 hover:underline ${title_class}`}>{title}</h3>
                        <p className={`mt-2 line-clamp-3 text-sm text-gray-600 ${description_class}`}>{description}</p>
                    </div>
                </a>

                {category_title && (
                    <div className="mt-3 flex items-center gap-x-4 text-xs">
                        <a
                            href={category_href}
                            className={`relative z-10 hover:underline rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:brightness-90 ${category_class}`}
                        >
                            {category_title}
                        </a>
                        {date && <time className="text-gray-500">{date}</time>}
                    </div>
                )}
                <div className="relative mt-4 flex items-center gap-x-4">
                    {author_image_url && <img alt={author_name} src={author_image_url} className="h-10 w-10 border border-[#702566] bg-gray-100" />}
                    <div className="text-sm leading-6">
                        <p className="font-semibold text-gray-900">
                            <a href={author_href}>{author_name}</a>
                        </p>
                        <p className="text-gray-600">{author_subtitle}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

Card.propTypes = {
    href: PropTypes.string.isRequired,
    date: PropTypes.string,
    title: PropTypes.string.isRequired,
    alt_text: PropTypes.string,
    image_url: PropTypes.string.isRequired,
    author_href: PropTypes.string.isRequired,
    author_name: PropTypes.string.isRequired,
    description: PropTypes.string,
    title_class: PropTypes.string,
    category_href: PropTypes.string.isRequired,
    category_title: PropTypes.string,
    category_class: PropTypes.string,
    container_class: PropTypes.string,
    author_subtitle: PropTypes.string,
    author_image_url: PropTypes.string.isRequired,
    description_class: PropTypes.string,
    apply_drop_shadow: PropTypes.string,
    image_url_class: PropTypes.string,
};

export default Card;
